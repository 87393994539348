// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

// ------FILE MODULES---------------------------------------------------------
import { fetchCatalogAsync, selectCatalog, selectCatalogState } from '../../../store/reducers/catalog';
import { fetchCustomersAsync, selectCustomers, selectCustomersState } from '../../../store/reducers/customers';
import { setError } from '../../../store/reducers/error';
import {
  CUSTOMER_STATUS,
  LEVELS_OF_ASSURANCE,
  NETWORK_IDS,
  REDUCER_STATUS,
  TECHNOLOGIES,
  TRUST_FRAMEWORKS
} from '#constants';
import { clearCredentialsCache } from '../../../store/reducers/adminCredentials';
import { clearCredentialRequestsCache } from '../../../store/reducers/adminCredentialRequests';
import config from '#config';

import services from '../../../services';
import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import Dropdown from '../../shared/Dropdowns/Dropdown.component';
import DynamicForm from '../DynamicForm/DynamicForm.component';
import DatePicker from '../../shared/DatePicker/DatePicker.component';
import { createHashForObject } from '../../../helpers/hash.helper';

// ---------------------------------------------------------------------------
// PRIVATE
// ---------------------------------------------------------------------------
function getFormDefaultState(credentialRequest) {
  const template = JSON.parse(
    credentialRequest.decoded.vcr.catalog.template ||
      `{"${credentialRequest.decoded.vcr.field_name}": "${credentialRequest.decoded.vcr.catalog.format || 'value'}"}`
  );
  const keys = Object.keys(template);
  const form = {};
  form[keys[0]] = credentialRequest.decoded.vcr.file;
  return form;
}

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function IssueCredentialPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const credentialRequest = props.credentialRequest;

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);

  const users = useSelector(selectCustomers);
  const usersState = useSelector(selectCustomersState);

  const dispatch = useDispatch();

  const [formSendState, setFormSendState] = useState(false);
  const [credential, setCredential] = useState(
    credentialRequest !== undefined ? credentialRequest.decoded.vcr.catalog : undefined
  );
  const [user, setUser] = useState(undefined);
  const [levelOfAssurance, setLevelOfAssurance] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dynamicFormValue, setDynamicFormValue] = useState(
    credentialRequest !== undefined ? getFormDefaultState(credentialRequest) : {}
  );

  const kid = 'default';
  const catalogLoading = catalogState === REDUCER_STATUS.LOADING;
  const catalogNeedsReload = catalogState === REDUCER_STATUS.NEEDS_REFRESH;
  const isLoadingUsers = usersState === REDUCER_STATUS.LOADING;
  const usersNeedsReload = usersState === REDUCER_STATUS.NEEDS_REFRESH;

  if (catalogNeedsReload) {
    dispatch(fetchCatalogAsync({ limit: null, jurisdictions: config.INTERNAL.CATALOG_JURISDICTIONS }));
  }

  if (usersNeedsReload) {
    dispatch(fetchCustomersAsync({ status: CUSTOMER_STATUS[1], limit: null }));
  }

  if (isLoadingUsers === false && credentialRequest !== undefined && user === undefined) {
    setUser(credentialRequest ? users.filter((user) => user.did === credentialRequest.decoded.iss)[0] : null);
  }

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setCredential(undefined);
    setUser(undefined);
    setLevelOfAssurance(null);
    setStartDate(null);
    setEndDate(null);
    setDynamicFormValue({});
    setIsOpen(false);
  };

  const isFormValid = () => {
    return (
      kid &&
      credential &&
      user &&
      levelOfAssurance !== null &&
      startDate &&
      endDate &&
      Object.keys(dynamicFormValue).length > 0
    );
  };

  const submitForm = async (event) => {
    event.preventDefault();

    if (!isFormValid()) return;

    const form = {
      trustFramework: TRUST_FRAMEWORKS[0],
      networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
      networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
      kid,
      validFrom: Math.round(startDate / 1000),
      validUntil: Math.round(endDate / 1000),
      credentialContext: credential.credentialContext || credential.context,
      credentialType: credential.type || credential.name || credential.alias,
      credentialSubject: {
        id: user.did,
        levelOfAssurance: levelOfAssurance.level,
        ...dynamicFormValue
      },
      credentialSchema: 'https://json-schema.org/draft/2020-12/schema'
    };

    if (credentialRequest) {
      const didMethods = credentialRequest.decoded.vcr.didMethods[0].split(':');
      form.trustFramework = didMethods[1];
      form.networkTechnology = didMethods[2];
      form.networkId = didMethods[3];
      form.credentialRequestJti = credentialRequest.storedJwt.jti;
    }

    setFormSendState(true);
    const keys = Object.keys(dynamicFormValue);

    if (keys.length > 0 && typeof dynamicFormValue[keys[0]] !== 'string') {
      const procHash = createHashForObject(form);

      form.credentialSubject[keys[0]] = await services.files.uploadFileToFileManager(
        form.credentialSubject[keys[0]],
        procHash,
        setErrorPopup
      );

      if (form.credentialSubject[keys[0]] === null) return;
    }

    form.credentialSubject = JSON.stringify(form.credentialSubject);

    await services.orchestrator.createCredential(form, setErrorPopup);

    setFormSendState(false);
    dispatch(clearCredentialsCache());
    dispatch(clearCredentialRequestsCache());
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('credential-emission-popup-emit-new-credential')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('credential-emission-popup-sending')} isLoading={formSendState}>
          <Grid container direction='column' justifyContent='center' alignItems='center'>
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('credential-emission-popup-credential-name')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('credential-emission-popup-credential-name-placeholder')}
                  value={credential}
                  required
                  isLoading={catalogLoading}
                  disabled={credentialRequest !== undefined}
                  options={catalog}
                  getOptionLabel={(catalogItem) => catalogItem.alias}
                  onChange={(catalogItem) => {
                    setCredential(catalogItem);
                    setDynamicFormValue({});
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('credential-emission-popup-issuer')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('credential-emission-popup-issuer-placeholder')}
                  value={user}
                  required
                  isLoading={isLoadingUsers}
                  disabled={credentialRequest !== undefined}
                  options={users}
                  getOptionLabel={(user) => user.businessName}
                  onChange={(user) => {
                    setUser(user);
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('credential-emission-popup-level-of-assurance')}
                  </Typography>
                </Grid>
                <Dropdown
                  label={translate('credential-emission-popup-level-of-assurance-placeholder')}
                  value={levelOfAssurance}
                  required
                  options={LEVELS_OF_ASSURANCE.map((level) => {
                    return { level };
                  })}
                  getOptionLabel={(level) => translate(`level-of-assurance-${level.level}`)}
                  getOptionValue={(level) => level.level}
                  onChange={(level) => {
                    setLevelOfAssurance(level);
                  }}
                  selectStyle={{
                    width: '22.125rem',
                    borderRadius: '5px',
                    fontSize: '0.938rem'
                  }}
                />
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container spacing={4}>
                <Grid item='true'>
                  <Grid container direction='column' alignItems='stretch'>
                    <Grid item='true'>
                      <Typography fontWeight='bold' fontSize='0.938rem'>
                        {translate('admin-credentials-create-start-date')}
                      </Typography>
                    </Grid>

                    <DatePicker
                      required
                      max={endDate ? Math.min(Date.now() + 86400000, endDate) : Date.now() + 86400000}
                      value={startDate}
                      onChange={(unixTime) => setStartDate(unixTime)}
                      width='10.438rem'
                    />
                  </Grid>
                </Grid>

                <Grid item='true'>
                  <Grid container direction='column' alignItems='stretch'>
                    <Grid item='true'>
                      <Typography fontWeight='bold' fontSize='0.938rem'>
                        {translate('admin-credentials-create-end-date')}
                      </Typography>
                    </Grid>

                    <DatePicker
                      required
                      min={Math.max(Date.now(), startDate)}
                      value={endDate}
                      onChange={(unixTime) => setEndDate(unixTime)}
                      width='10.438rem'
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <DynamicForm
                translate={translate}
                formJson={
                  credential
                    ? credential.template ||
                      `{"${credential.name || credential.alias}": "${credential.format || 'value'}"}`
                    : '{}'
                }
                format={credential ? credential.format || 'value' : ''}
                fixedValue={credentialRequest ? credentialRequest.decoded.vcr.file : undefined}
                value={dynamicFormValue}
                onChange={setDynamicFormValue}
              />
            </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('credential-emission-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    onClick={submitForm}
                    color='secondary'
                    variant='contained'
                    disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('credential-emission-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
