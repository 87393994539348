import * as constants from './constants';
import * as mockedData from './mockedData.config'

// API configuration
const apiVersion = 4;
const apiURL = process.env.REACT_APP_API_URL || 'https://dev.sybolid.veialab.com/veia/v4';
const authServerURL = process.env.REACT_APP_AUTH_SERVER_URL || 'https://dev.sybolid.veialab.com/veia/v4/authserver/oauth'

// EXTERNAL services configuration
const blockExplorerURL = '//explorer.';
const fileExplorerURL = '//files.';
const helpDeskURL = process.env.REACT_APP_HELPDESK_URI || 'https://grantthornton-es.atlassian.net/servicedesk/customer/portal/2';

// INTERNAL configuration
const mocked = false;
const useAuth = true;
const axiosTimeout = 120000;
const logo = process.env.REACT_APP_LOGO_PATH || '/images/logo.png';
const tabTitle = process.env.REACT_APP_TAB_TITLE;
const userNameSource = process.env.REACT_APP_USER_NAME_SOURCE || constants.USER_NAME_SOURCES.WHO_AM_I;
const theme = process.env.REACT_APP_THEME || 'sybolTheme';
const version = process.env.REACT_APP_VERSION || 'v1';
const pollingRate = process.env.REACT_APP_POLLING_RATE || 10000;
const mainPage = process.env.REACT_APP_MAIN_PAGE || '';
const showDigitalSignature = process.env.REACT_APP_DIGITAL_SIGNATURE || false;
const showBlockExplorer = process.env.REACT_APP_BLOCK_EXPLORER || true;
const showHelpDesk = process.env.REACT_APP_HELP_DESK || true;
const catalogJurisdiction = process.env.REACT_APP_CATALOG_JURISDICTIONS || '';
let jurisdictionsArray = catalogJurisdiction.split(',');

const config = {
  API: {
    VERSION: apiVersion,
    URL: apiURL,
    AUTH_SERVER_URL: authServerURL
  },
  EXTERNAL_SERVICES: {
    BLOCK_EXPLORER: blockExplorerURL,
    FILE_EXPLORER: fileExplorerURL,
    HELP_DESK: helpDeskURL,
    SHOW_BLOCK_EXPLORER: showBlockExplorer,
    SHOW_HELP_DESK: showHelpDesk
  },
  INTERNAL: {
    MOCKED: mocked,
    AUTH_ENABLED: useAuth,
    AXIOS_TIMEOUT: axiosTimeout,
    LOGO: logo,
    TAB_TITLE: tabTitle,
    USER_NAME_SOURCE: userNameSource,
    THEME: theme,
    VERSION: version,
    POLLING_RATE: pollingRate,
    MAIN_PAGE: mainPage,
    SHOW_DIGITAL_SIGNATURE: showDigitalSignature,
    CATALOG_JURISDICTIONS: jurisdictionsArray
  },
  constants: constants,
  mockedData: mockedData
};

export default config;