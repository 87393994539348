import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import { Translate } from '@mui/icons-material';

import config from '#config';
import BlockExplorerIcon from '../../shared/Icons/BlockExplorerIcon.component';
import HelpdeskIcon from '../../shared/Icons/HelpdeskIcon.component';
import ButtonWithOutline from '../../../components/shared/Buttons/ButtonWithOutline.component';

import LogoutButtonComponent from '../../private/LogoutButtons/LogoutButton.component';
import Hider from '../../shared/Hider/Hider.component';

const DEFAULT_TRANSLATE = (string) => {
  return string;
};
const DEFAULT_ANCHOR_ELEMENT = undefined;
const DEFAULT_SET_ANCHOR_ELEMENT = () => {};

export default function UserButtons(props) {
  const translate = props.translate || DEFAULT_TRANSLATE;
  const anchorElement = props.anchorElement || DEFAULT_ANCHOR_ELEMENT;
  const setAnchorElement = props.setAnchorElement || DEFAULT_SET_ANCHOR_ELEMENT;
  const changeLanguage = props.changeLanguage;

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Menu
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={handleClose}
      sx={{
        '.MuiPaper-root': {
          minWidth: '15%'
        }
      }}>
      <Hider isHidden={!(config.EXTERNAL_SERVICES.SHOW_BLOCK_EXPLORER === 'true')}>
      <MenuItem
        href={`${window.location.protocol}${config.EXTERNAL_SERVICES.BLOCK_EXPLORER}${window.location.hostname}`}
        target='_blank'
        component='a'
        onClick={handleClose}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <BlockExplorerIcon sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('user-action-open-block-explorer')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
      </Hider>
      <Hider isHidden={!(config.EXTERNAL_SERVICES.SHOW_HELP_DESK === 'true')}>
      <MenuItem href={config.EXTERNAL_SERVICES.HELP_DESK} target='_blank' component='a' onClick={handleClose}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <HelpdeskIcon sx={{ color: '#662D91' }} />
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('user-action-open-helpdesk')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>
      </Hider>
      <MenuItem target='_blank' component='a' onClick={changeLanguage}>
        <Grid container spacing={1}>
          <Grid item='true'>
            <Translate sx={{ color: '#662D91', fontSize: '24px', fontWeight: '100px'}}/>
          </Grid>
          <Grid item='true'>
            <Typography sx={{ color: '#662D91' }} fontSize='0.938rem'>
              {translate('user-action-change-language')}
            </Typography>
          </Grid>
        </Grid>
      </MenuItem>

      <LogoutButtonComponent translate={translate} onClick={handleClose} />
    </Menu>
  );
}
