// ---------------------------------------------------------------------------
// IMPORTS
// ---------------------------------------------------------------------------

// ------NODE MODULES---------------------------------------------------------
import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SpinnerLoader from '../../shared/Loader/SpinnerLoader.component';
import FileInput from '../../shared/FileInput/FileInput.component';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import services from '../../../services';
import Grid from '@mui/material/Grid2';

// ------FILE MODULES---------------------------------------------------------
import { fetchCatalogAsync, selectCatalogState, selectCatalog } from '../../../store/reducers/catalog';
import { clearEntityCredentialRequestsCache } from '../../../store/reducers/entityCredentialRequests';
import { NETWORK_IDS, REDUCER_STATUS, TECHNOLOGIES, TRUST_FRAMEWORKS } from '#constants';
import { createHashForObject } from '../../../helpers/hash.helper';
import { setError } from '../../../store/reducers/error';
import config from '#config';

// ---------------------------------------------------------------------------
// EXPORTS
// ---------------------------------------------------------------------------
export default function DigitalSignatureAddDocumentPopup(props) {
  const translate = props.translate;
  const isOpen = props.isOpen;
  const setIsOpen = props.setIsOpen;
  const users = props.users;
  const credentialPreSelection = props.credentialPreSelection;
  const setIsPrLoading = props.setIsPrLoading;

  const catalog = useSelector(selectCatalog);
  const catalogState = useSelector(selectCatalogState);

  const [formSendState, setFormSendState] = useState(false);
  const [credential, setCredential] = useState(undefined);
  const [dynamicFormValue, setDynamicFormValue] = useState({});
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  if (catalogState === REDUCER_STATUS.NEEDS_REFRESH) {
    dispatch(fetchCatalogAsync({ limit: null, jurisdictions: config.INTERNAL.CATALOG_JURISDICTIONS }));
  }

  if (catalogState === REDUCER_STATUS.READY && credential === undefined && credentialPreSelection !== undefined) {
    setCredential(catalog.find((catalogEntry) => catalogEntry.name === credentialPreSelection) || null);
  }

  const kid = 'default';

  const setErrorPopup = (error) => {
    dispatch(setError(error));
  }

  const handleClose = () => {
    if (formSendState) return;

    setCredential(undefined);
    setDynamicFormValue({});
    setUser(null);
    setIsOpen(false);
  };

  const isFormValid = () => {
    return kid && credential && user && Object.keys(dynamicFormValue).length > 0;
  };

  // const submitForm = async (event) => {
  //   event.preventDefault();

  //   if (!isFormValid()) return;
  //   const form = {
  //     trustFramework: TRUST_FRAMEWORKS[0],
  //     networkTechnology: sessionStorage.getItem('networkTechnology') || TECHNOLOGIES[1],
  //     networkId: sessionStorage.getItem('networkId') || NETWORK_IDS[2],
  //     kid,
  //     sub: user.did,
  //     credentialContext: credential.context,
  //     credentialType: credential.name,
  //     file: ''
  //   };

  //   const dynamicFormKeys = Object.keys(dynamicFormValue);

  //   if (dynamicFormKeys.length > 0) {
  //     form.file = dynamicFormValue[dynamicFormKeys[0]];
  //   }

  //   setFormSendState(true);

  //   if (typeof form.file !== 'string') {
  //     const procHash = createHashForObject(form);
  //     form.file = await services.files.uploadFileToFileManager(form.file, procHash, setErrorPopup);

  //     if (form.file === null) return;
  //   }

  //   await services.orchestrator.createCredentialRequest(form, setErrorPopup);

  //   setFormSendState(false);
  //   dispatch(clearEntityCredentialRequestsCache());

  //   if (setIsPrLoading) {
  //     setIsPrLoading(undefined);
  //   }

  //   handleClose();
  // };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle fontWeight='bold' fontSize='1.75rem'>
        {translate('digital-signature-add-document-popup')}
      </DialogTitle>
      <DialogContent sx={{ whiteSpace: 'pre-line' }}>
        <SpinnerLoader text={translate('entity-credentials-page-popup-sending')} isLoading={formSendState}>
          {/* <Grid container direction='column' justifyContent='center' alignItems='center'> */}
          <Grid >
            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('digital-signature-sign-catalog-input')}
                  </Typography>
                </Grid>
                <TextField fullWidth></TextField>
              </Grid>
            </Grid>

            <Grid item='true' paddingTop='10px'>
              <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('digital-signature-sign-catalog-description')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
 
            <Grid container direction='column' justifyContent='center' alignItems='stretch'>
                <Grid item='true'>
                  <Typography fontWeight='bold' fontSize='0.938rem'>
                    {translate('dynamic-form-attach-file')}
                  </Typography>
                </Grid>
                <Grid item='true' paddingTop={1} paddingRight={1} paddingBottom={1}>
                  <FileInput required/>
                </Grid>
              </Grid>

            <Grid item='true' padding='25px'>
              <Grid container direction='row' justifyContent='center' alignItems='center' spacing={3}>
                <Grid item='true'>
                  <Button
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('entity-credentials-page-popup-cancel-button')}
                  </Button>
                </Grid>
                <Grid item='true'>
                  <Button
                    // onClick={submitForm}
                    onClick={handleClose}
                    color='secondary'
                    variant='contained'
                    // disabled={!isFormValid()}
                    sx={{
                      border: '1px solid #E5E5E5',
                      fontSize: '0.938rem',
                      fontWeight: '600',
                      width: '130px',
                      boxShadow: 'none',
                      textTransform: 'none',
                      '&:hover': {
                        border: '1.25px solid #E5E5E5',
                        boxShadow: 'none'
                      }
                    }}>
                    {translate('entity-credentials-page-popup-send-button')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          
        </SpinnerLoader>
      </DialogContent>
    </Dialog>
  );
}
